import { useNavbarHeader } from 'components/navbar/NavbarHeaderContext';
import Seo from 'components/seo/Seo';
import { NextPage } from 'next';
import { Trans, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Button from 'components/ui/button/Button';
import PageLayout from 'components/layout/PageLayout';
import Badge from 'components/ui/badge/Badge';
import Link from 'next/link';
import Footer from 'components/footer/Footer';
import { useRouter } from 'next/router';
import { Routes } from 'lib/routes';
import { defaultNs } from 'lib/i18n/defaults';
import Image from 'next/image';
import getConfig from 'next/config';
import CreateAlertCTA from 'components/ui/cta/CreateAlertCTA';

const { publicRuntimeConfig } = getConfig()
const { cdnEndpoint } = publicRuntimeConfig

const Home: NextPage = () => {

    const { locale } = useRouter()
    const { t } = useTranslation();
    const { navbarHeaderRef } = useNavbarHeader();

    return (
        <div>
            <Seo pageKey="home" titleTemplate={`${t('seo:default.title')} - %s`} />
            <header className="z-10 comingSoonShape" ref={navbarHeaderRef}>
                <div className="flex items-center px-4 pt-8 pb-8 md:pb-16 md:pt-16 md:pr-0 min-h-4/5-screen">
                    <div className="flex flex-col items-start w-full max-w-md pt-5 mx-auto lg:w-6/12 lg:max-w-xl">

                        <h1 className="py-2 text-center md:text-left">
                            <span className="text-3xl font-extrabold md:text-5xl leading-cosy md:leading-cosy">{t('home:title')}</span>
                            <sup className="ml-2 text-sm font-extrabold uppercase md:ml-4 -top-3 md:-top-5 leading-cosy md:leading-cosy text-primary-500">{t('home:beta')}</sup>
                        </h1>
                        <h2 className="py-2 mt-4 text-xl text-center md:mt-8 md:text-left">
                            <Trans i18nKey="home:subtitle">
                                A<span className="subtitleUnderline">B</span>C
                            </Trans>
                        </h2>

                        <div className="flex flex-col items-center w-full py-5 md:w-auto md:mt-4">
                            <Link passHref href={Routes.JOB_BOARD}>
                                <Button className="w-full mb-2 font-bold primary sm:w-96 h-14">
                                    {t('home:seeAllJobsCTA')}
                                </Button>
                            </Link>
                            <Link passHref href={Routes.OFFER_CREATE}>
                                <Button className="w-full mt-3 mb-2 font-bold outline sm:w-96 h-14">
                                    {t('home:postJobCTA')}
                                </Button>
                            </Link>
                            <span className="self-center text-sm">
                                {t('home:postJobAddendum')}
                            </span>
                        </div>

                    </div>
                    <div className="items-center justify-center hidden w-0 md:flex md:w-6/12">
                        <Image priority src={`/images/mockups/desktop-phone-${locale}.png`} alt="available on your phone" width={1350} height={928} />
                    </div>
                </div>
            </header>

            <section className="bg-white">
                <PageLayout py="lg" width="md" className="md:mb-10">
                    <div className="flex flex-col items-center justify-between w-full space-y-8 md:space-y-0 md:flex-row md:items-start ">
                        <div className="flex flex-col items-center w-56 pt-12">
                            <Image className="flex-shrink-0" src="/images/shapes/targeted.svg" width={210} height={150} />
                            <span className="mb-1.5 mt-4 text-sm font-extrabold uppercase  text-primary-500">
                                {t('home:targeted')}
                            </span>
                            <span className="text-xl text-center">
                                {t('home:targetedText')}
                            </span>
                        </div>
                        <div className="flex flex-col items-center w-56 pt-12">
                            <Image className="flex-shrink-0" src="/images/shapes/tailored.svg" width={210} height={150} />
                            <span className="mb-1.5 mt-4 text-sm font-extrabold uppercase  text-primary-500">
                                {t('home:tailored')}
                            </span>
                            <span className="text-xl text-center">
                                {t('home:tailoredText')}
                            </span>
                        </div>
                        <div className="flex flex-col items-center w-56 pt-12">
                            <Image className="flex-shrink-0" src="/images/shapes/tech.svg" width={210} height={150} />
                            <span className="mb-1.5 mt-4 text-sm font-extrabold uppercase  text-primary-500">
                                {t('home:tech')}
                            </span>
                            <span className="text-xl text-center">
                                {t('home:techText')}
                            </span>
                        </div>
                    </div>
                </PageLayout>
            </section>

            <section className="pt-12 bg-gray-100 md:pt-0">
                <PageLayout className="flex flex-wrap-reverse items-center justify-center gap-y-10 gap-x-10" py="md">
                    <Image width={272} height={200} className="flex-shrink-0 w-full md:w-1/2" src="/images/shapes/recruiters.png" />
                    <div className="flex flex-col w-full space-y-5 md:w-1/2">
                        <span className="text-3xl font-extrabold">
                            {t('home:recruitersSection.title')}
                        </span>
                        <span className="text-lg mt-2.5">
                            <Link passHref href={Routes.OFFER_CREATE}>
                                <a>
                                    <Trans i18nKey="home:recruitersSection.subtitle">
                                        <span className="font-bold underline text-primary-500">link</span>text
                                    </Trans>
                                </a>
                            </Link>
                        </span>
                        <div className="flex flex-wrap">
                            <Badge color="gray-blue" className="mb-2 mr-2">{t('home:recruitersSection.badges.publicSchools')}</Badge>
                            <Badge color="gray-blue" className="mb-2 mr-2">{t('home:recruitersSection.badges.privateSchools')}</Badge>
                            <Badge color="gray-blue" className="mb-2 mr-2">{t('home:recruitersSection.badges.universities')}</Badge>
                            <Badge color="gray-blue" className="mb-2 mr-2">{t('home:recruitersSection.badges.languageCentres')}</Badge>
                        </div>
                    </div>
                </PageLayout>
            </section>


            <section className="bg-white">
                <PageLayout className="flex flex-wrap items-center justify-center my-12 gap-y-10 md:min-h-4/5-screen md:my-auto" py="md">
                    <div className="flex flex-col w-full md:w-1/2">
                        <span className="text-4xl font-extrabold">
                            <Trans i18nKey="home:teachersSection.title">Teachers of English, <br />Teachers who teach in English,</Trans>
                        </span>
                        <span className="text-lg mt-2.5">
                            <Trans i18nKey="home:teachersSection.subtitle">
                                Subscribe to receive <span className="text-primary-500">personalized</span> offers by email today.
                            </Trans>
                        </span>
                        <div className="flex flex-wrap mt-8">
                            <Badge className="mb-2 mr-2 ">{t('home:teachersSection.badges.schoolTeachers')}</Badge>
                            <Badge className="mb-2 mr-2 ">{t('home:teachersSection.badges.freelancers')}</Badge>
                            <Badge className="mb-2 mr-2 ">{t('home:teachersSection.badges.universityProfessors')}</Badge>
                            <Badge className="mb-2 mr-2 ">{t('home:teachersSection.badges.onlineTeachers')}</Badge>
                            <Badge className="mb-2 mr-2 ">{t('home:teachersSection.badges.trainers')}</Badge>
                            <Badge className="mb-2 mr-2 ">{t('home:teachersSection.badges.examiners')}</Badge>
                            <Badge className="mb-2 mr-2 ">{t('home:teachersSection.badges.translators')}</Badge>
                            <Badge className="mb-2 mr-2 ">{t('home:teachersSection.badges.assistants')}</Badge>
                            <Badge className="mb-2 mr-2 ">{t('home:teachersSection.badges.tutors')}</Badge>
                        </div>
                        <div className="flex flex-col items-center mt-10 md:items-start ">
                            <Link passHref href={Routes.JOB_BOARD}>
                                <Button className="w-full font-bold primary sm:w-96 h-14">
                                    {t('home:seeAllJobsCTA')}
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <Image width={560} height={411} className="w-full max-w-lg mt-12 md:w-1/2 md:mt-0" src="/images/shapes/teachers.png" />
                </PageLayout>
            </section>

            <section className="bg-gray-100 lg:py-10 ">
                <PageLayout width="lg" py="sm">
                    <div className="flex flex-col items-start justify-around px-4 space-x-0 md:flex-row mt-14 md:space-x-8 md:space-y-0 space-y-14 ">
                        <div>
                            <div className="text-3xl font-extrabold capitalize w-min">
                                <div className="whitespace-nowrap">{t('home:ourMissions')}</div>
                                <div className="h-1 ml-20 border-b-4 rounded-lg border-primary-500 "> </div>
                            </div>
                            <div className="mt-5">
                                <ul className="text-lg">
                                    <li>
                                        <Trans i18nKey="home:ourMissionsDetails.1"><b>Centralise</b> English teaching job offers in France</Trans>
                                    </li>
                                    <li>
                                        <Trans i18nKey="home:ourMissionsDetails.2"><b>Accelerate</b> the search process</Trans>
                                    </li>
                                    <li>
                                        <Trans i18nKey="home:ourMissionsDetails.3"><b>Boost</b> visibility for teachers and establishments</Trans>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="text-3xl font-extrabold capitalize w-min">
                                <div className="whitespace-nowrap">{t('home:ourValues')}</div>
                                <div className="h-1 ml-20 border-b-4 rounded-lg border-primary-500 "> </div>
                            </div>
                            <div className="mt-5">
                                <ul className="text-lg">
                                    <li>{t('home:ourValuesDetails.1')}</li>
                                    <li>{t('home:ourValuesDetails.2')}</li>
                                    <li>{t('home:ourValuesDetails.3')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </PageLayout>
            </section>

            <section className="bg-gray-100">
                <PageLayout width="lg" py="sm">
                    <CreateAlertCTA title={t('home:createAlertCTA.title')}
                        btnText={t('home:createAlertCTA.buttonText')} />
                </PageLayout>
            </section>

            <Footer />
        </div>
    )
}

export default Home;

export const getStaticProps = async ({ locale }) => ({
    props: {
        ...await serverSideTranslations(locale, [...defaultNs, 'home', 'job-offer']),
    }
})