
interface Props {
    className?: string
    color?: 'gray' | 'gray-blue' | 'white'
}
const bgColors = {
    'gray': 'bg-gray-150',
    'gray-blue': 'bg-gray-200',
    'white': 'bg-white'
}

const Badge: React.FC<React.PropsWithChildren<Props>> = ({ className, children, color = "gray" }) => {
    const bgColor = bgColors[color];
    return (
        <div className={`${className} ${bgColor} px-3 py-2 text-sm  rounded`}>
            {children}
        </div>
    )
}

export default Badge